import React from "react";
import Layout from "../components/layout.js"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { navigate } from "@reach/router";
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';

const getClasses = makeStyles(() =>({
  card: {
    height: '11em',
  }
}));


const data = {
  installer: {
    version: '1.3.1',
    local_url: '/files/nano_installer_1.3.1.tar.gz',
    git_url: 'https://github.com/project-nano/releases/releases',
    sha1: '43c2b0ec283867fc7bfcdc697520213ff1a10208'
  },
  image: [
    {
      url: '/files/centos7_minimal.qcow2',
      name: 'CentOS 7.5 Minimal',
      os: 'CentOS 7.5',
      sha1: '0749cccf5012b96e8e1459ba6eb308cb3ded10ca',
      description: {
        cn: 'CentOS 7.5(1804) Minimal带qemu-agent及acpid服务，默认用户root/123456',
        en: 'CentOS 7.5(1804) Minimal bundle with qemu-agent and acpid. Default user:root/123456'
      }
    },
    {
      url: '/files/centos7.5-cloud-init.qcow2',
      name: 'CentOS 7.5 Minimal with Cloud-init',
      os: 'CentOS 7.5',
      sha1: 'c029b8a95d0f69c088b0f240a013eebead224748',
      description: {
        cn: 'CentOS 7.5(1804) Minimal预装cloud-init/clout-utils，支持通过Nano自动初始化cloud-init实例，默认用户root/123456',
        en: 'CentOS 7.5(1804) Minimal bundle with cloud-init and cloud-utils, enable auto-initialization when boot by Nano. Default user:root/123456',
      }
    },
  ]
}

const i18n = {
  'cn':{
    title: '下载',
    installer: '获取Nano',
    installerDescription: (
      <Typography component='div'>
        <p>
          Nano在<Link href='https://github.com/project-nano'>Github</Link>提供了全部源代码，你可以自行编译并进行部署。
        </p>
        <p>
          但是我们更推荐直接使用Installer进行部署，Installer集成了最新模块版本以及依赖RPM包，能够对平台和环境进行自动安装和配置，管理员只需要下载并执行就能完成Nano平台的搭建。
          具体操作可以参考<Link href="/quick_start">快速安装指引</Link>
        </p>
      </Typography>
    ),
    requirements: (
      <div>
        <b>服务器要求</b><br/>
        <ul>
          <li>至少一台支持虚拟化的X86服务器或者打开嵌套虚拟化（Intel VT-x/AMD-v）的虚拟机</li>
          <li>2核4G内存50GB磁盘和一个网络设备</li>
          <li>CentOS 7 Minimal</li>
          <li>操作系统安装完成并且网络就绪</li>
          <li>如有Raid/LVM请先完成配置，再进行Nano安装</li>
          <li>浏览器: Chrome或者Firefox</li>
        </ul>
      </div>
    ),
    image: '预置镜像',
    imageDescription: (
      <Typography component='div'>
        <p>
          预制镜像已经安装好操作系统以及云主机需要使用的系统组件（比如qemu-agen、cloud-init和acpid等），并且做好了网络配置，将预制的云主机镜像上传到Nano平台就可以直接开始批量克隆云主机，为您节省安装和制作模板的时间。
        </p>
        <p>
          您同样也可以通过光盘镜像从零开始制作满足自己需求的云主机模板。
        </p>
      </Typography>
    ),
    version: '版本',
    address: '下载地址',
    localDownload: '本地下载',
    name: '名称',
    os: '操作系统',
    description: '描述',
  },
  'en':{
    title: 'Download',
    installer: 'Installer',
    installerDescription: (
      <Typography component='div'>
        <p>
          All source code published on <Link href='https://github.com/project-nano'>Github</Link>, you can compile and deploy manually.
        </p>
        <p>
          However, the Installer recommends to automatically install and configure the environment, which integrates the latest module binaries and dependent RPM package. Just download and execute the installer to build a new platform in minutes. See instructions in <Link href="/en/quick_start">Quick Guide</Link>.
        </p>
      </Typography>
    ),
    requirements: (
      <div>
        <b>Minimal System Requirements:</b><br/>
        <ul>
          <li>Virtualization enabled X86 servers, or nested virtualization enabled virtual machines(Intel VT-x/AMD-v)</li>
          <li>2 cores/4 GB memory/50 GB disk/1 network interface</li>
          <li>CentOS 7 Minimal</li>
          <li>Operation system installed with network ready</li>
          <li>If you have raid or LVM, please configure them before installing Nano.</li>
          <li>Browser: Chrome/Firefox</li>
        </ul>
      </div>
    ),
    image: 'Prebuild Images',
    imageDescription: (
      <Typography component='div'>
        <p>
        A prebuild image has the operating system and components such as QEMU-Agent/Cloud-init/ACPID installed. You can start cloning instance once uploading a prebuild image immediately. Save you a lot of time of installing and configuration.
        </p>
        <p>
          You can also create a host template that meets your needs from scratch with an ISO image.
        </p>
      </Typography>
    ),
    version: 'Version',
    address: 'Address',
    localDownload: 'Local Download',
    name: 'Name',
    os: 'OS',
    description: 'Description',
  }
}

function Installer(props){
  const { lang } = props;
  const texts = i18n[lang];
  const href = data.installer.local_url;
  let redirectURL;
  if ('cn' === lang){
    redirectURL = '/download_success/?link=' + encodeURIComponent(href);
  }else{
    redirectURL = '/'+ lang + '/download_success/?link=' + encodeURIComponent(href);
  }
  return (
    <Box m={2} p={1}>
      <Typography variant='h5'>
        {texts.installer}
      </Typography>
      {texts.installerDescription}
      {texts.requirements}
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{texts.version}</TableCell>
              <Hidden smDown>
                <TableCell>SHA1 Hash</TableCell>
              </Hidden>
              <TableCell>{texts.address}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{data.installer.version}</TableCell>
              <Hidden smDown>
                <TableCell>{data.installer.sha1}</TableCell>
              </Hidden>
              <TableCell>
                <Box p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    href={href}
                    onClick={ () => {
                        navigate(redirectURL);
                      }
                    }
                    >
                      {texts.localDownload}
                  </Button>
                </Box>
                <Box p={1}>
                  <Button variant="contained" color="secondary" href={data.installer.git_url} fullWidth>Github</Button>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
}


function Images(props){
  const { lang } = props;
  const texts = i18n[lang];
  const classes = getClasses();
  return (
    <Box m={2} p={1}>
      <Typography variant='h5'>
        {texts.image}
      </Typography>
      {texts.imageDescription}
      <Grid container spacing={2}>
        {data.image.map(image => (
          <Grid item xs={12} sm={6}>
            <Card key={image.sha1}>
              <CardContent className={classes.card}>
                <Typography component='div' variant='h6'>
                  <Link href={image.url}> {image.name}</Link>
                </Typography>
                <Chip label={image.os} color='primary'/>
                <Box mt={1} mb={2}>
                <Typography component='div'>
                  {image.description[lang]}
                </Typography>
                </Box>
                <Typography component='div' variant='caption'>
                  SHA1sum: {image.sha1}
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="secondary" href={image.url}>{texts.localDownload}</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default function(props){
  const defaultLang = 'cn';
  const lang = props.pageContext.lang || defaultLang;
  const texts = i18n[lang];
  return (
      <Layout title={texts.title} keywords={['Installer', 'CentOS', 'Image', 'Download']} lang={lang}>
        <Container maxWidth="md">
          <Installer lang={lang}/>
          <Divider/>
          <Images lang={lang}/>
        </Container>
      </Layout>
  );
}
